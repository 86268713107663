<template>
  <div class="Info" :style="'height:' + Height + 'px'">
    <Nav title="修改个人信息"></Nav>
    <div class="Centen">
      <van-form @submit="onSubmit">
        <van-field
          v-model="update_info.realname"
          name="姓名"
          label="姓名"
          placeholder="姓名"
        />
        <van-field
          v-model="update_info.nickname"
          name="昵称"
          label="昵称"
          placeholder="昵称"
        />
        <van-field
          v-model="update_info.student_num"
          name="学号"
          label="学号"
          placeholder="学号"
        />
        <van-field
          v-model="update_info.school_name"
          name="学校"
          label="学校"
          placeholder="学校"
        />
        <van-field
          v-model="update_info.specialty"
          name="专业"
          label="专业"
          placeholder="专业"
        />
        <!-- <van-field
          v-model="update_info.grade"
          name="年级"
          label="年级"
          placeholder="年级"
        /> -->
        <van-field
          readonly
          clickable
          name="picker"
          :value="update_info.grade"
          label="年级"
          placeholder="点击选择年级"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="gradeList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="update_info.education_background"
          name="学历"
          label="学历"
          placeholder="学历"
        />
        <van-field
          v-model="update_info.faculty_adviser"
          name="指导老师"
          label="指导老师"
          placeholder="指导老师"
        />
        <van-field
          v-model="update_info.email"
          name="邮箱"
          label="邮箱"
          placeholder="邮箱"
        />
		<div style="margin: 16px;">
			<van-button round block type="info" native-type="submit">提交</van-button>
		</div>
      </van-form>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/H5/navigation.vue";
import {
  getInfo,
  update_user
} from "../../api/ThisInfo.js";
import { Notify } from "vant";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      Height: document.documentElement.clientHeight,
      gradeList: [],
      showPicker: false,
	  update_info:{}
    };
  },
  created() {
	  this.GetgetInfo()
    this.getNf();
  },
  methods: {
	GetgetInfo() {
      getInfo().then((res) => {
        this.update_info = res.data;
      });
    },
    getNf() {
      var nfOptionsArray = new Array();
      var years = new Date().getFullYear();
      for (let i = years - 9; i <= years; i++) {
        nfOptionsArray.push(i+'');
      }
      this.gradeList = nfOptionsArray;
    },
	onConfirm(value){
		this.update_info.grade = value;
      	this.showPicker = false;
	},
	onSubmit(){
		delete this.update_info.username;
		update_user({ update_info: this.update_info }).then((res) => {
			if (res.code == 200) {
				// this.GetgetInfo();
				Notify({ type: "success", message: '个人信息修改成功' });
				this.$router.push('/PersonalInfoH5');
			}
		});
	}
  },
};
</script>

<style lang="scss" scoped>
.Centen {
  width: calc(100% - 20px);
  margin: 0 auto;
  margin-top: 44px;
}
</style>